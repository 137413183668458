import { useEffect, useState } from 'react'
import { hostUrl } from '../../lib/base/base'
import './index.css';
import Axios from 'axios';


const Advertise = () => {
    const [adAlliance, setAdAlliance] = useState([{
        address: '',
        price: '',
        img: '',
        advert: '',
        title: '',
        context: '',
    }])
    const adDate = async () => {
        const res = await Axios.get(`${hostUrl}/api/adAlliance`, {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache'
            }
        });
        if (res.data.data.dataList) {
            setAdAlliance(res.data.data.dataList)
        }
    }
    useEffect(() => {
        adDate();
    }, [])
    return <div className='advertise'>
        {
            adAlliance.map((item, index) => {
                return <a href={item.address} target='_blank' rel="noreferrer">
                    <div className='ad-alliance-fix' key={index}>
                        <div className='ad-alliance-img'>
                            <img className='ad-img' src={item.img} alt='图片' />
                            <p className='font'>{item.price}元</p>
                        </div>
                        <div className='ad-alliance-context'>
                            <h4 className={item.advert}>{item.title}</h4>
                            <p>{item.context}</p>
                        </div>
                    </div>
                </a>
            })
        }
    </div>
}

export default Advertise