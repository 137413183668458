import { useEffect, useState } from 'react';
import { Input, message, Pagination } from 'antd';
import Axios from 'axios';
import { hostUrl } from '../../lib/base/base';

import './index.css'


const Preferential = () => {
  // 展示数据
  const [adPreferential, setAdPreferential] = useState([{
    advert: '',
    title: '',
    la: '',
    address: '',
    context: '',
    original: '',
    discount: '',
    price: '',
    img: '',
  }]);
  // 显示翻页总数
  const [total, setTotal] = useState<number>(0);
  const [hint, setHint] = useState(false);
  const { Search } = Input;
  // 搜索接口
  const onSearch = async (e: any) => {
    if (!e) {
      message.error('请输入产品名称')
    }
    const reqData = {
      title: e
    }
    try {
      const res = await Axios({
        method: 'post',
        url: `${hostUrl}/api/searchPreferential`,
        params: reqData
      });
      if (res.data.data.dataList) {
        const resData = res.data.data.dataList;
        setAdPreferential(resData)
      }
      setHint(true)
    } catch (error) {
      message.error('查询出差');
    }

  }
  // 翻页
  const onChangeValue = async (e: number) => {
    try {
      const reqData = {
        currentPage: e
      }
      const res = await Axios({
        method: 'post',
        url: `${hostUrl}/api/preferentialPage`,
        params: reqData
      });
      if (res.data.data.dataList) {
        // 页面显示数据
        const resData = res.data.data.dataList.rows;
        // 分页数据
        const total = res.data.data.dataList.count;
        setAdPreferential(resData);
        setTotal(total);
      }
    } catch (error) {
      message.error('查询出差');
    }
  }
  useEffect(() => {
    onChangeValue(1);
  }, []);
  return <>
    <div className='ad-perfer'>
      <div className='search-input'>
        <Search
          placeholder="搜索商品名称"
          allowClear
          enterButton="商品查找"
          size="large"
          onSearch={onSearch}
        />
      </div>

      <div className='preferential'>
        {
          adPreferential.map((item, index) => {
            return <a href={item.address} target='_blank' rel="noreferrer" style={{ maxWidth: '26.5em' }}>
              <div className='ad-preferential-fix' key={index}>
                <div className='ad-preferential-img'>
                  <img className='ad-img' src={item.img} alt='图片' />
                  <p className='font'>{item.la}</p>
                </div>
                <div className='ad-preferential-context'>
                  <h4 className={item.advert}>{item.title}</h4>
                  <p>{item.context}</p>
                  <p className='p-originalPrice'>原价：{item.original}元</p>
                  <p className='p-discount'>优惠：{(Number(item.original) - Number(item.price)).toFixed(2)}元</p>
                  <p className='p-price'>现价：{item.price}元</p>
                </div>
              </div>
            </a>
          })
        }
      </div>
    </div>
    {hint === true ? <div className='hint'>获取最新优惠，请刷新一下网页...</div> : <Pagination style={{marginTop: '10px'}} defaultCurrent={1} total={total} showSizeChanger={false} pageSize={50} align="center" onChange={(e) => {
      onChangeValue(e)
    }} />}
  </>
};


export default Preferential;