import React, { ReactNode, useEffect, useState } from 'react';
import { message } from 'antd';
import { LuckyWheel } from '../../lib/lucky-wheel/lucky-wheel';
import { EditGift } from '../../lib/edit-gift/edit-gift';
import { createFun } from '../useContext';
import './index.css'

type EditGift = () => ReactNode;

const initialValue = 'Apple Watch，iPhone16，小米汽车，华为三折叠，黑悟空，任天堂游戏机，香奈儿，迪奥，雅诗兰黛，赫莲娜，Mac pro， 万科物，碧桂园，保利，华润，耐克球鞋，三叶草女鞋'
const Gift = () => {
  const [editLucky, setEditLucky] = useState(0);
  const [editValue, setEditValue] = useState(initialValue);
  const [luckyWheelData, setLuckyWheelData] = useState([{ id: 0, luckyName: '', probability: 0.2 }]);
  const useContextFun = { setEditValue, setEditLucky, editValue };
  const handleClickValue = () => {
    if (editValue === '') {
      message.error('请输入需要抓阄内容');
    }
    const editArray = editValue.split('，');
    // 数据处理
    const luckyData = [];
    for (let i = 0; i < editArray.length; i++) {
      if (editArray[i] !== '') {
        luckyData.push(
          { id: i, luckyName: editArray[i], probability: 0.2 },
        )
      }
    }
    setLuckyWheelData(luckyData);
    setEditLucky(1)
  };
  useEffect( () => {
    if (editValue !== '') {
      handleClickValue()
    }
  }, [])
  return (
    <>
      <createFun.Provider value={useContextFun}>
        {editLucky === 1 ?
          <LuckyWheel items={luckyWheelData} tableLink={'抓阄'} />
          : <>
            <EditGift />
            <div className='button-click'>
              <div className='edit-gift-button' onClick={handleClickValue}>
                保存
              </div>
            </div>
          </>
        }
      </createFun.Provider>
    </>
  );
}

export default Gift
